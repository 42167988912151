<mat-card>
    <form role="form" #login='ngForm' novalidate>
      <img id="logo" src="/logo.png" width="100%">
      <mat-form-field>
        <input matInput type="text" required [(ngModel)]="user.username" id="username" name="username">
        <mat-placeholder>
          <i class="material-icons app-input-icon">face</i>
          <span>{{'Username'|translate}}</span>
        </mat-placeholder>
      </mat-form-field>
      <br>
      <mat-form-field>
        <input matInput type="password" required [(ngModel)]="user.password" id="password" name="password">
        <mat-placeholder>
          <i class="material-icons app-input-icon">lock_open</i>
          <span>{{'Password'|translate}}</span>
        </mat-placeholder>
      </mat-form-field>
      <br>
      <button (click)="loginUser()" mat-raised-button color="accent" name="action">{{'LOGIN'|translate}}</button>
      <span id="err">
        <small>{{errorMessage}}</small>
      </span>
    </form>
</mat-card>